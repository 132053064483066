<template>
  <div class="notice-wrap" style="background:#F5F5F5;">
    <div class="index-wrap" :style="{ background: backgroundColor }">
			<div class="index" style="width: 100%;padding-left: 0px;">
				<div class="banner">
					<el-carousel height="430px" arrow="hover" v-loading="loadingAd" @change="handleChange">
						<el-carousel-item v-for="item in adList" :key="item.adv_id">
							<el-image style="width:100%;height:100%;" :src="$img(item.adv_image)" fit="cover" @click="$router.pushToTab(item.adv_url.url)" />
						</el-carousel-item>
					</el-carousel>
				</div>
			</div>
		</div>
    
    <el-breadcrumb separator="/" class="path" style="background:#fff;">
      <div style="max-width: 1210px;margin:0 auto;">
        <el-breadcrumb-item :to="{ path: '/' }" class="path-home">
          首页
        </el-breadcrumb-item>
        <el-breadcrumb-item class="path-help">关于我们</el-breadcrumb-item>
      </div>
    </el-breadcrumb>
    <div v-loading="loading" style="background:#F5F5F5;margin:0 auto;margin-top:105px;">
      <div class="richtextCss" v-html="richtext" style="text-align:center;font-size:48px;"></div>
      <!-- <div class="block">
        <el-carousel trigger="click" height="618px">
          <el-carousel-item  v-for="(item, index) in lunboxtext" :key="index">
            <img :src="item.pic_path" alt="">
          </el-carousel-item>
        </el-carousel>
      </div>      -->
    </div>
    <!-- <div>
      <p style="font-size:48px;color:#414141;text-align: center;">Company Introduction</p>
      <p style="font-size:30px;color:#414141;text-align: center;">荣誉证书</p>
    </div> -->
    <div style="width:1210px;display:flex;margin:0 auto;justify-content: space-between;margin-top:80px;flex-wrap: wrap;">
      <div style="width:292.5px;text-align:center;margin-top:10px;" v-for="(item, index) in rongyupicture" :key="index">
        <!-- <div style="height:50px;"></div> -->
        <img :src="item.pic_path" alt="" style="width:100%;height:233px;">
      </div>
    </div>



    <div class="notice" v-loading="loading" style="margin: 0 auto;">
    </div>
    <div class="pager">
        <el-pagination
			background
			:pager-count="5" 
			:total="total" 
			prev-text="上一页" 
			next-text="下一页" 
			:current-page.sync="queryinfo.page" 
			:page-size.sync="queryinfo.page_size"
			@size-change="handlePageSizeChange" 
			@current-change="handleCurrentPageChange" 
			hide-on-single-page
		></el-pagination>
      </div>
  </div>
</template>

<script>
import { noticesList } from "@/api/cms/notice";
import index from '@/views/index/_index/index.js';
export default {
  name: "notice",
  components: {},
  mixins: [index],
  data: () => {
    return {
      rongyupicture:[],
      queryinfo: {
        page: 1,
        page_size: 10,
		    receiving_type : "web"
      },
      noticeList: [],
      total: 0,
      loading: true,
      richtext:"",
      lunboxtext:[]
    };
  },
  created() {

    this.getList();
    var _this=this;

    _this.$ajax.post(
      `https://tengpaidoor.jiajushe.com.cn/api/Demo/rongyu`,
      _this.$qs.parse({
        // id:_this.category_id
      })
    )
    .then(e=> {
      // _this.lunboxtext=e.data
      // _this.richtext=e.data.content
      _this.rongyupicture=e.data

      for(let i in _this.rongyupicture){
        
        if(i>6){
          _this.rongyupicture.splice(i, 1)
        }
      }
    })
    .catch(error=>{
      console.log(error)
    })


    _this.$ajax.post(
      `https://tengpaidoor.jiajushe.com.cn/api/Demo/rongyulunbo`,
      _this.$qs.parse({
        // id:_this.category_id
      })
    )
    .then(e=> {
      _this.lunboxtext=e.data 
      // _this.richtext=e.data.content
      // console.log("fdhgdhdfhdfhgfdgfd",e.data)
    })
    .catch(error=>{
      console.log(error)
    })


    _this.$ajax.post(
      `https://tengpaidoor.jiajushe.com.cn/api/Demo/guanyu`,
      _this.$qs.parse({
        // id:_this.category_id
      })
    )
    .then(e=> {
      _this.richtext=e.data.content
      // console.log("fdhgdhdfhdfhgfdgfd",e.data)
    })
    .catch(error=>{
      console.log(error)
    })
  },
  methods: {
    detil(id) {
      this.$router.push({ path: "/cms/notice-" + id });
    },
    getList() {
      noticesList(this.queryinfo)
        .then(res => {
          if (res.code == 0 && res.data) {
            this.noticeList = res.data.list;
            this.total = res.data.count;
          }
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$message.error(err.message);
        });
    },
    handlePageSizeChange(newsize) {
      this.queryinfo.page_size = newsize;
      this.getList();
    },
    handleCurrentPageChange(newnum) {
      this.queryinfo.page = newnum;
      this.getList();
    }
  }
};
</script>
<style lang="scss" scoped>
.notice-wrap {
  background: #fff;
  .path {
    padding: 15px 0;
  }
}
.notice {
  background-color: #F5F5F5;
  min-height: 300px;
  position: relative;
  display: flex;
  .menu {
    width: 210px;
    min-height: 300px;
    background: #ffffff;
    border: 1px solid #f1f1ff;
    flex-shrink: 0;
    .title {
      padding-left: 16px;
      background: #f8f8f8;
      font-size: $ns-font-size-base;
      height: 40px;
      cursor: pointer;
      color: #666666;
      display: flex;
      align-items: center;
    }
    .item-name {
      font-size: $ns-font-size-base;
      cursor: pointer;
      line-height: 40px;
      border-top: 1px solid #f1f1f1;
      padding-left: 25px;
      padding-right: 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 40px;
      background: #ffffff;
      color: #666666;
      &:hover {
        color: $base-color;
      }
    }
    .active {
      color: $base-color;
    }
  }
  .list-wrap {
    padding: 0 40px;
    margin-left: 27px;
    border: 1px solid #f1f1f1;
    width: 100%;
    margin-bottom: 10px;
    .notice-title {
      padding: 37px 0 20px 0;
      font-size: 18px;
      border-bottom: 1px dotted #e9e9e9;
    }
    .list {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &:last-of-type {
        border-bottom: initial;
      }
      &:nth-child(2) {
        margin-top: 10px;
      }
      .item {
        font-size: $ns-font-size-base;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        padding: 3px 0;
        &:hover {
          color: $base-color;
        }
      }
      .info {
        display: flex;
        font-size: $ns-font-size-base;
        .time {
          margin-right: 10px;
        }
      }
    }
  }
}
  .page {
    text-align: center;
  }
  .richtextCss::v-deep p{
      // color: rgb(209, 40, 40);
  }
</style>
